/*global
alert, confirm, console, prompt
*/

(function( $ ) {
	$(document).ready(function(){

		// var menuHeight = $(".amenities-interactive-graphics").height();
		// $(".in-building-amenities").height(menuHeight).css({
		// 	overflow: "hidden"
		// });

		$("#svg-rooftop").click(function(){
			$('.building-plan').removeClass('active show')
			$('#building-plan-1').addClass('active show')
			$(".cls-1").removeClass('visible')
			$("#svg-rooftop .cls-1").addClass('visible')
		});

		$("#svg-conference").click(function(){
			$('.building-plan').removeClass('active show')
			$('#building-plan-2').addClass('active show')
			$(".cls-1").removeClass('visible')
			$("#svg-conference .cls-1").addClass('visible')
		});

		$("#svg-fitness").click(function(){
			$('.building-plan').removeClass('active show')
			$('#building-plan-3').addClass('active show')
			$(".cls-1").removeClass('visible')
			$("#svg-fitness .cls-1").addClass('visible')
		});

		$("#svg-hotel").click(function(){
			$('.building-plan').removeClass('active show')
			$('#building-plan-4').addClass('active show')
			$(".cls-1").removeClass('visible')
			$("#svg-hotel .cls-1").addClass('visible')
		});

		$("#svg-lobby").click(function(){
			$('.building-plan').removeClass('active show')
			$('#building-plan-5').addClass('active show')
			$(".cls-1").removeClass('visible')
			$("#svg-lobby .cls-1").addClass('visible')
		});

	});
})( jQuery );